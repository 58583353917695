/* eslint-disable  */

import {getDynamicLabels} from '../../../../../api/forms';

const DynamicLabels = async (currentForm, formInfo, field, value,setCurrentDate) => {

    if(value===null || value?.id===undefined) {
        return false;
    }

    //Determine if field being updated is a relational field.
    const idx =currentForm.columnsettings.findIndex(itm=>itm.data===field);
    if(idx !==-1 && currentForm.columnsettings[idx].fieldInfo.uitype===1 && currentForm.columnsettings[idx].fieldInfo.lookup==='single') {  
        
        //First step is we need to make a copy of the label/content values to maintain the tags
        //that's because a user can change the selected value, and we will need to update the token each time.
        //we will use the originalContent 
        if(currentForm.columnsettings[idx].originalContent===undefined) {
            currentForm.columnsettings.map(itm=>{
                if(itm.type==='rtf') {
                    itm.originalContent=itm.content;
                } else {
                    itm.originalContent = itm.label;
                }
            })
        }

        //GPT Generated.
        //The token used to specify the form field and corresponding table field, and potentially
        //relational lookup field needs to have the field part (first part of token) removed, as the logic to replace the
        // tokens just needs to the actual table tokens (not the form field to designate).
        //This processString logic remove the form field lookup part.

        const processString = (str) => {
            const regex = /({{2,3})\s*[^|}]+\|\|(.+?)(}{2,3})/g;
        
            return str.replace(regex, (match, openingBraces, content, closingBraces) => {
                // Reconstruct the string with the captured opening and closing braces
                return `${openingBraces}${content}${closingBraces}`;
            });
        };
    
        //12-19-23 first check for this single relation field if any dynamic labels reference it.
        //if not return false
        const hasDynamicLabels = currentForm.columnsettings
        .findIndex(itm=> itm.originalContent.includes(`[[${currentForm.columnsettings[idx].header}`)) !==-1

        if(!hasDynamicLabels) {
            return false;
        }
    
        //Find all labels that reference the relational field and construct the "templateFields" from this.
        const templateFields=[];
        currentForm.columnsettings
        .filter(itm=> itm.originalContent.includes(`[[${currentForm.columnsettings[idx].header}`))
        .map(fld=>{
            const test =fld.originalContent.replaceAll('[','{').replaceAll(']','}')
            const newStr =processString(test)
            templateFields.push({field:fld.id,fieldValue:newStr})
        })

        const tblid =currentForm.columnsettings[idx].fieldInfo.source.lookuptableid;

        const mergedContent = await getDynamicLabels(value.id,formInfo,templateFields,tblid, currentForm.addedby)

        if(mergedContent !==undefined && mergedContent !==null) {
            Object.keys(mergedContent[0]).map(k =>{
                const idx =currentForm.columnsettings.findIndex(el=>el.id===k)

                if(idx !==-1) {
                    if(k.startsWith('rtf')) {
                        currentForm.columnsettings[idx].content=mergedContent[0][k];
                    } else {
                        currentForm.columnsettings[idx].label=mergedContent[0][k];
                    }
                }
            })
        }

         // we call this to force re-render and have labels appear.
         setCurrentDate(new Date)
         return false;

    
    } else {
        return false // if not single relational field, return 
    }
}

export default DynamicLabels