/* eslint-disable */
import React, { useEffect, useState, useContext } from 'react';
import { Input, Dropdown, Form, TextArea,Radio } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import { mdiClose, mdiEmail, mdiPlus, mdiChat } from '@mdi/js';
import Modal from 'react-modal';
import { AccountContext } from '../../providers/AccountProvider';
import Global from '../../clients/global';
import GBSwitch from '../../components/GBSwitch/GBSwitch';
import IconButton from '../Block/components/FilterData/IconButton';
import TextButton from '../../components/TextButton/TextButton';
import GBButton from '../../components/GBButton/GBButton';
import TextControl from 'src/components/TextControl/TextControl';
import EmailControl from '../Block/components/MessageView/EmailControl';

const ActionSendMessage = ({ localData, updateData, editMode, sourceNodes }) => {
  const { userInfo } = useContext(AccountContext);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [showChatBox, setShowChatBox] = useState(localData.textMessage !==undefined && localData.textIsActive ? true : false);
  const [fromAddresses, setFromAddresses] = useState([
    {
      key: '1',
      value: 'clientemaildelivery@graceblocks.com',
      text: 'clientemaildelivery@graceblocks.com',
    },
  ]);
  const [fromAddress, setFromAddress] = useState(localData.fromAddress ?? 'clientemaildelivery@graceblocks.com');
  const [SMSNumber, setSMSNumber]=useState(localData.SMSNumber ?? '');
  const [emailFields, setEmailFields] = useState([]);
  const [textFields, setTextFields] = useState([]);
  const [allFields, setAllFields] = useState([]);

  const [emailIsActive, setEmailIsActive] = useState(localData.emailIsActive ?? true);
  const [textIsActive, setTextIsActive] = useState(localData.textIsActive ?? false);
  const [selectedEmail, setSelectedEmail] = useState(
    localData.selectedEmail ?? [{ id: 1, field: 'manual', value: '' }],
  );
  const [selectedEmailAlt,setSelectedEmailAlt] = useState(localData.selectedEmailAlt ?? [{ id: 1, field: 'manual', value: '' }])
  const [selectedText, setSelectedText] = useState(
    localData.selectedText ?? [{ id: 1, field: 'manual', value: '' }],
  );
  const [selectedTextAlt, setSelectedTextAlt] = useState(
    localData.selectedTextAlt ?? [{ id: 1, field: 'manual', value: '' }],
  );

  const [refreshDate, setRefreshDate] = useState(Date.now());
  const [emailMessage, setEmailMessage] = useState(
    localData.emailMessage ?? 
    {
    subject: '',
    message: ``,
    fromAddress: 'clientemaildelivery@graceblocks.com',
    fromName: '',
    replyTo: '{{recordSpecific}}',
  });
  const [TwilioNumbers,setTwilioNumbers] = useState([]);

  const [textMessage, setTextMessage] =useState(localData.textMessage ?? '');

  useEffect(() => {
    loadData();
  }, []);

  // const addEmail = () => {
  //   const copyEmailSelected = structuredClone(selectedEmail);
  //   const id = Math.floor(Math.random() * 1000);
  //   copyEmailSelected.push({ id: id.toString(), field: 'manual', value: '' });
  //   setSelectedEmail(copyEmailSelected);

  //   localData.selectedEmail = copyEmailSelected;
  //   updateData(localData);
  // };

  const removeEmail = (id) => {
    const copyEmailSelected = structuredClone(selectedEmail).filter((el) => el.id !== id);
    setSelectedEmail(copyEmailSelected);
    localData.selectedEmail = copyEmailSelected;
    updateData(localData);
  };

  const removeText = (id) => {
    const copyTextSelected = structuredClone(selectedText).filter((el) => el.id !== id);
    setSelectedText(copyTextSelected);
    localData.selectedText = copyTextSelected;
    updateData(localData);
  };

  // const updateReplyConfiguration =(val) =>{
  //   setReplyConfiguration(val);
  //   localData.replyConfiguration = val;
  //   updateData(localData);
  // }

  const addText = () => {
    const copyTextSelected = structuredClone(selectedText);
    const id = Math.floor(Math.random() * 1000);
    copyTextSelected.push({ id: id.toString(), field: 'manual', value: '' });
    setSelectedText(copyTextSelected);

    localData.selectedText = copyTextSelected;
    updateData(localData);
  };

  const updateTextMessage =(val) =>{
    setTextMessage(val);

    localData.textMessage= val;
    updateData(localData)
  }

  const updateEmailisActive = () => {
    setEmailIsActive(!emailIsActive);
    localData.emailIsActive = !emailIsActive;
    updateData(localData);
  };

  const updateTextisActive = () => {
    //7-8-24 only show if they have active configured phone number in system.

    if(TwilioNumbers.length>0){
    setTextIsActive(!textIsActive);
    localData.textIsActive = !textIsActive;
    if(localData.selectedText===undefined) {
      setSelectedText([{id:1,field:"manual",value:''}])
      localData.selectedText = [{id:1,field:"manual",value:''}]
    }

    updateData(localData);
    } else {
      toast.info(
        <div style={{ margin: '5px' }}>
         You must first purchase a Twilio number via the admin panel. 
        </div>,
        {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 7000,
          closeButton: true,
        },
      );
    }
  };

  const loadData = async () => {
    getEmailandCollaboratorFields();

    const currentZone = userInfo.zones.filter((el) => el.id === parseInt(Global.zoneid))[0];
    let tempAddresses = [
      {
        key: '1',
        value: 'clientemaildelivery@graceblocks.com',
        text: 'clientemaildelivery@graceblocks.com',
      },
    ];
    if (currentZone.attributes.fromAddresses) {
      currentZone.attributes.fromAddresses.map((el) => {
        if (el.isActive) {
          tempAddresses.push({ key: el, value: el.email, text: el.email });
        }
      });
      setFromAddresses(tempAddresses);
    }

    //Load Twilio numbers to be configured for sending text messages;
    let TwilioNumbersTemp =[];
    currentZone.attributes.PhoneNumbers?.map(itm=>{
      if(itm.isActive) {
        TwilioNumbersTemp.push({key:itm.number,value:itm.number,text:itm.number})
      }
    })
      setTwilioNumbers(TwilioNumbersTemp);
  };

  const updateEmailValue = (fld, val) => {
    const copySelectedEmail = structuredClone(selectedEmail);
    const idx = copySelectedEmail.findIndex((el) => el.id === fld.id);
    if (idx !== -1) {
      copySelectedEmail[idx].value = val;
    }

    if(localData.emailIsActive===undefined) {
      localData.emailIsActive=true;
    }

    setSelectedEmail(copySelectedEmail);
    localData.selectedEmail = copySelectedEmail;
    updateData(localData);
  };

  const updateEmailAltValue = (fld, val) => {
    const copySelectedEmailAlt = structuredClone(selectedEmailAlt);
    const idx = copySelectedEmailAlt.findIndex((el) => el.id === fld.id);
    if (idx !== -1) {
      copySelectedEmailAlt[idx].value = val;
    }

    if(localData.emailIsActive===undefined) {
      localData.emailIsActive=true;
    }

    setSelectedEmailAlt(copySelectedEmailAlt);
    localData.selectedEmailAlt = copySelectedEmailAlt;
    updateData(localData);
  };

  const updateTextValue = (id, val) => {
    const copySelectedText = structuredClone(selectedText);
    const idx = copySelectedText.findIndex((el) => el.id === id);
    if (idx !== -1) {
      copySelectedText[idx].value = val;
    }

    setSelectedText(copySelectedText);
    localData.selectedText = copySelectedText;
    updateData(localData);
  };

  const updateTextAltValue = (id, val) => {
    const copySelectedTextAlt = structuredClone(selectedTextAlt);
    const idx = copySelectedTextAlt.findIndex((el) => el.id === id);
    if (idx !== -1) {
      copySelectedTextAlt[idx].value = val;
    }

    setSelectedText(copySelectedTextAlt);
    localData.selectedTextAlt = copySelectedTextAlt;
    updateData(localData);
  };

  const updateFromAddress = (val) => {
    const copyEmailMessage = structuredClone(emailMessage);
    copyEmailMessage.fromAddress = val;
    setFromAddress(val);
    setEmailMessage(copyEmailMessage);
    
    localData.fromAddress = val;
    localData.emailMessage =copyEmailMessage;

    updateData(localData);
  };

  const updateSMSNumber =(val) =>{
    setSMSNumber(val);
    localData.SMSNumber = val;
    updateData(localData);
  }

  const getEmailandCollaboratorFields = () => {
    const emailOptions = [{ key: '0', value: 'manual', text: 'Manual entry' }];
    const textOptions = [{ key: '0', value: 'manual', text: 'Manual entry' }];
    const allOptions =[{ key: '0', value: '', text: 'Choose field' }];

    sourceNodes.map((node) => {
     !node.data.insertMultiple && node.data?.tableData?.tableinfo?.columns
        .filter(
          (el) =>
            (el.uitype == 8 && el.lookup==='single') ||
            (el.source?.lookupuitype === 8 && el.lookup==='single' && el.source?.lookup==='single') ||
            el.uitype === 10 || (el.source?.lookupuitype === 10 && el.source?.lookup==='single'),
        )
        .map((col) => {
          const idx = emailOptions.findIndex(itm=>itm.key===`${node.data.tableData.id}|${col.data}`)
          if(idx ===-1) {
          emailOptions.push({
            key: `${node.data.tableData.id}|${col.data}`,
            value: `${node.data.tableData.id}|${col.data}`,
            text: `${node.data.tableData.name} (${col.header})`,
          });
        }

        });
    });

    sourceNodes.map((node) => {
      !node.data.insertMultiple && node.data?.tableData?.tableinfo?.columns
        .filter(
          (el) =>
            el.uitype == 9 ||
            (el.source?.lookupuitype === 9 && el.source.lookup==='single')
        )
        .map((col) => {
          const idx = textOptions.findIndex(itm=>itm.key===`${node.data.tableData.id}|${col.data}`)
          if(idx === -1) {
          textOptions.push({
            key: `${node.data.tableData.id}|${col.data}`,
            value: `${node.data.tableData.id}|${col.data}`,
            text: `${node.data.tableData.name} (${col.header})`,
          });
        }
        });
    });

    sourceNodes.map((node) => {
      !node.data.insertMultiple  && node.data?.tableData?.tableinfo?.columns
        .map((col) => {
          const idx = allOptions.findIndex(itm=>itm.key===`${node.data.tableData.id}||${col.data}`)
         if(idx === -1) {
          allOptions.push({
            key: `${node.data.tableData.id}||${col.data}`,
            value:`${node.data.tableData.name}::${node.data.tableData.id}||${col.header}`,
            text: `${node.data.tableData.name}||${col.header}`,
          });
        }
        });
    });

    setEmailFields(emailOptions);
    setTextFields(textOptions);
    setAllFields(allOptions);
  };

  const handleEmailSelection = (fld, value) => {
    const copySelectedEmail = structuredClone(selectedEmail);
    const idx = copySelectedEmail.findIndex((el) => el.id === fld.id);
    if (idx !== -1) {
      copySelectedEmail[idx].field = value;
    }
    setSelectedEmail(copySelectedEmail);
    if(localData.emailIsActive===undefined) {
      localData.emailIsActive=true;
    }

    localData.selectedEmail = copySelectedEmail;
    updateData(localData);
  };

  const handleEmailAltSelection = (fld, value) => {
    const copySelectedEmailAlt = structuredClone(selectedEmailAlt);
    const idx = copySelectedEmailAlt.findIndex((el) => el.id === fld.id);
    if (idx !== -1) {
      copySelectedEmailAlt[idx].field = value;
    }
    setSelectedEmailAlt(copySelectedEmailAlt);
    if(localData.emailIsActive===undefined) {
      localData.emailIsActive=true;
    }

    localData.selectedEmailAlt = copySelectedEmailAlt;
    updateData(localData);
  };

  const handleTextSelection = (fld, value) => {
    const copySelectedText = structuredClone(selectedText);
    const idx = copySelectedText.findIndex((el) => el.id === fld.id);
    if (idx !== -1) {
      copySelectedText[idx].field = value;
    }
    setSelectedText(copySelectedText);

    localData.selectedText = copySelectedText;
    updateData(localData);
  };

  const handleTextAltSelection = (fld, value) => {
    const copySelectedTextAlt = structuredClone(selectedTextAlt);
    const idx = copySelectedTextAlt.findIndex((el) => el.id === fld.id);
    if (idx !== -1) {
      copySelectedTextAlt[idx].field = value;
    }
    setSelectedTextAlt(copySelectedTextAlt);

    localData.selectedTextAlt = copySelectedTextAlt;
    updateData(localData);
  };

  const configureEmail = (
    emailObjectArray,
    messageSetting,
    templateFields,
    ids,
    blockRoles,
    role,
  ) => {
    const copyEmail = structuredClone(emailMessage);
    if (templateFields !== null) {
      copyEmail.message = templateFields.filter((el) => el.field === 'message')[0].fieldValue;
      copyEmail.fromName = templateFields.filter((el) => el.field === 'fromName')[0].fieldValue;
      copyEmail.subject = templateFields.filter((el) => el.field === 'subject')[0].fieldValue;
      copyEmail.replyTo = templateFields.filter((el) => el.field === 'replyTo')[0].fieldValue;
      if (templateFields.filter((el) => el.field === 'CC')[0].fieldValue !== '') {
        copyEmail.CC = templateFields.filter((el) => el.field === 'CC')[0].fieldValue;
      } else {
        delete copyEmail.CC;
      }
      
      if (templateFields.filter((el) => el.field === 'BCC')[0].fieldValue !== '') {
        copyEmail.BCC = templateFields.filter((el) => el.field === 'BCC')[0].fieldValue;
      } else {
        delete copyEmail.BCC
      }

      if (templateFields.filter((el) => el.field === 'files')[0].fieldValue !== '') {
        copyEmail.files = templateFields.filter((el) => el.field === 'files')[0].fieldValue;
      } else {
        delete copyEmail.files;
      }

      setEmailMessage(copyEmail);
    } else if (emailObjectArray !== null) {
      copyEmail.message = emailObjectArray[0].message;
      copyEmail.fromName = emailObjectArray[0].fromName;
      copyEmail.subject = emailObjectArray[0].subject;
      copyEmail.replyTo = emailObjectArray[0].replyTo;
      if (emailObjectArray[0].CC !== '') {
        copyEmail.CC = emailObjectArray[0].CC;
      }
      if (emailObjectArray[0].BCC !== '') {
        copyEmail.BCC = emailObjectArray[0].BCC;
      }
      if (emailObjectArray[0].files !== null) {
        copyEmail.files = emailObjectArray[0].files;
      }
      setEmailMessage(copyEmail);
    }

    localData.emailMessage = copyEmail;
    updateData(localData)

    setModalIsOpen(false);
  };

  return (
    <div
      style={{
        position:'relative',
        backgroundColor: '#fff',
        minHeight: '300px',
        border: '1px solid #0D99FF',
        borderRadius: '5px',
        padding: '20px',
        paddingBottom: '20px',
      }}
    >
      <div style={{ marginBottom: '10px', fontSize: '20px', fontWeight: 'bold' }}>
        Action details
      </div>
      <div>
        <div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
              marginTop: '5px',
              marginBottom: '5px',
            }}
          >
            <div>
              <GBSwitch
                text="Send email message"
                isChecked={emailIsActive}
                Action={updateEmailisActive}
              />
            </div>
            <div style={{ width: '30px' }}></div>
            <div>
              <GBSwitch
                text="Send text message"
                isChecked={textIsActive}
                Action={updateTextisActive}
              />
            </div>
          </div>
        </div>
        {emailIsActive ? (
          <>
            <div style={{ marginTop: '10px', marginBottom: '10px', fontWeight: 'bold' }}>
              From address:{' '}
              <Dropdown
                selectOnBlur={false}
                fluid
                value={fromAddress}
                selection
                options={fromAddresses}
                onChange={(e, data) => updateFromAddress(data.value)}
              />
            </div>

            <div style={{ fontWeight: 'bold' }}>
              Email to <span style={{ color: 'red', fontSize: '15px' }}>*</span>{' '}
            </div>
            {selectedEmail.map((fld,idx) => (
              
              <div
                key={idx.toString()}
                style={{
                  width: '100%',
                  marginBottom: '10px',
                  // border: '1px solid #0D99FF',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <div style={{ width: '100%' }}>
                Primary email field
                  <Dropdown
                    selectOnBlur={false}
                    options={emailFields}
                    value={fld.field}
                    selection
             
                    fluid
                    onChange={(e, data) => handleEmailSelection(fld, data.value)}
                  />
                  {fld.field === 'manual' ? (
                    <div style={{ marginTop: '10px' }}>
                      <Input
                        value={fld.value}
                        fluid
                        label="Email"
                        onChange={(e, data) => updateEmailValue(fld, data.value)}
                      />
                    </div>
                  ) : null}
                </div>
                {fld.id !== 1 ? (
                  <div style={{ marginLeft: '20px' }}>
                    <IconButton
                      iconSize="20px"
                      color="red"
                      hoverColor="white"
                      hoverBackgroundColor="#F6685E"
                      icon={mdiClose}
                      Action={removeEmail}
                      ActionValue={fld.id}
                    />
                  </div>
                ) : null}
              </div>
            ))}

          {selectedEmailAlt.map((fld,idx) => (
              <div
                key={idx.toString()}
                style={{
                  width: '100%',
                  marginBottom: '10px',
                  // border: '1px solid #0D99FF',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <div style={{ width: '100%' }}>
                 Alternative email field
                  <Dropdown
                    placeholder="Alt email field"
                    selectOnBlur={false}
                    options={emailFields}
                    value={fld.field}
                    selection
                    fluid
                    onChange={(e, data) => handleEmailAltSelection(fld, data.value)}
                  />
                  {fld.field === 'manual' ? (
                    <div style={{ marginTop: '10px' }}>
                      <Input
                        value={fld.value}
                        fluid
                        label="Email"
                        onChange={(e, data) => updateEmailAltValue(fld, data.value)}
                      />
                    </div>
                  ) : null}
                </div>
                {fld.id !== 1 ? (
                  <div style={{ marginLeft: '20px' }}>
                    <IconButton
                      iconSize="20px"
                      color="red"
                      hoverColor="white"
                      hoverBackgroundColor="#F6685E"
                      icon={mdiClose}
                      Action={removeEmail}
                      ActionValue={fld.id}
                    />
                  </div>
                ) : null}
              </div>
            ))}


            {/* <div style={{ marginTop: '10px' }}>
              <TextButton
                hoverColor="#0D99FF80"
                textColor="#0D99FF"
                iconColor="#0D99FF"
                iconSize="20px"
                text="Add another to line"
                Action={addEmail}
                icon={mdiPlus}
                iconPosition="left"
              />
            </div> */}

            <div style={{ marginTop: '10px' }}>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <div>
                  <GBButton
                    text="Configure email"
                    icon={mdiEmail}
                    iconSize="20px"
                    fontSize='15px'
                    iconColor="white"
                    iconHoverColor="white"
                    iconPosition="left"
                    borderRadius="10px"
                    Action={() => setModalIsOpen(true)}
                    ActionValue={'trigger'}
                    textColor="white"
                    width="maxContent"
                    padding='10px'
                    fontWeight='normal'
                    color="#0D99FF"
                    textHoverColor="white"
                    hoverBackgroundColor={'#0D99FF80'}
                  />
                </div>
              </div>
            </div>
          </>
        ) : null}

        {textIsActive ? (
          <>
            <div style={{ marginTop: '20px' }}>
            <div style={{ marginTop: '10px', marginBottom: '10px', fontWeight: 'bold' }}>
              From number <span style={{ color: 'red', fontSize: '15px' }}>*</span>{' '}
              <Dropdown 
              selectOnBlur={false}
              value={SMSNumber} 
              options={TwilioNumbers} 
              fluid 
              selection
              onChange={(e,data)=> updateSMSNumber(data.value)}
              />
             </div>

              <div style={{ fontWeight: 'bold' }}>
                Text to <span style={{ color: 'red', fontSize: '15px' }}>*</span>{' '}
              </div>
              {selectedText.map((fld) => (
                <div
                  style={{
                    width: '100%',
                    marginBottom: '10px',
                    // border: '1px solid #0D99FF',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <div style={{ width: '100%' }}>
                    Primary phone field
                    <Dropdown
                      selectOnBlur={false}
                      options={textFields}
                      value={fld.field}
                      selection
                      fluid
                      onChange={(e, data) => handleTextSelection(fld, data.value)}
                    />
                    {fld.field === 'manual' ? (
                      <div style={{ marginTop: '10px' }}>
                         <TextControl
                          value={fld.value}
                          key={fld.key}
                          showLabel={false}
                          index={0}
                          alignControl={true}
                          blockid={71}
                          updateValue={updateTextValue}
                          tableid={sourceNodes[0]?.data?.tableData?.id}
                          colInfo={{data:fld.id,uitype:9,header:'Phone'}}
                          role={3}
                          canEdit={true}
                          editMode={true}
                        />
                      </div>
                    ) : null}
                  </div>
                  {fld.id !== 1 ? (
                    <div style={{ marginLeft: '20px' }}>
                      <IconButton
                        iconSize="20px"
                        color="red"
                        hoverColor="white"
                        hoverBackgroundColor="#F6685E"
                        icon={mdiClose}
                        Action={removeText}
                        ActionValue={fld.id}
                      />
                    </div>
                  ) : null}
                </div>
              ))}

          {selectedTextAlt.map((fld) => (
                <div
                  style={{
                    width: '100%',
                    marginBottom: '10px',
                    // border: '1px solid #0D99FF',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <div style={{ width: '100%' }}>
                    Alternative phone field
                    <Dropdown
                      selectOnBlur={false}
                      options={textFields}
                      value={fld.field}
                      selection
                      fluid
                      onChange={(e, data) =>handleTextAltSelection(fld, data.value)}
                    />
                    {fld.field === 'manual' ? (
                      <div style={{ marginTop: '10px' }}>
                         <TextControl
                          value={fld.value}
                          key={fld.key}
                          showLabel={false}
                          index={0}
                          alignControl={true}
                          blockid={71}
                          updateValue={updateTextAltValue}
                          tableid={sourceNodes[0]?.data?.tableData?.id}
                          colInfo={{data:fld.id,uitype:9,header:'Phone'}}
                          role={3}
                          canEdit={true}
                          editMode={true}
                        />
                      </div>
                    ) : null}
                  </div>
                  {fld.id !== 1 ? (
                    <div style={{ marginLeft: '20px' }}>
                      <IconButton
                        iconSize="20px"
                        color="red"
                        hoverColor="white"
                        hoverBackgroundColor="#F6685E"
                        icon={mdiClose}
                        Action={removeText}
                        ActionValue={fld.id}
                      />
                    </div>
                  ) : null}
                </div>
              ))}

            </div>
            <div style={{ marginTop: '10px' }}>
              <TextButton
                hoverColor="#0D99FF80"
                textColor="#0D99FF"
                iconColor="#0D99FF"
                iconSize="20px"
                text="Add another phone number"
                Action={addText}
                icon={mdiPlus}
                iconPosition="left"
              />
            </div>

            <div style={{ marginTop: '10px' }}>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <div>
                  <GBButton
                    text="Configure text message"
                    icon={mdiChat}
                    iconSize="20px"
                    iconColor="white"
                    iconHoverColor="white"
                    iconPosition="left"
                    borderRadius="10px"
                    Action={() => setShowChatBox(!showChatBox)}
                    ActionValue={'trigger'}
                    textColor="white"
                    width="200px"
                    padding='10px'
                    fontWeight='normal'
                    color="#0D99FF"
                    textHoverColor="white"
                    hoverBackgroundColor={'#0D99FF80'}
                  />
                </div>
              </div>
            </div>
            {showChatBox ? (
              <div style={{marginTop:'10px'}}>
              <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                <div style={{whiteSpace:'nowrap',marginRight:'5px'}}>Field tokens</div>
              <Dropdown selectOnBlur={false} options={allFields} fluid selection onChange={(e,data)=>updateTextMessage(textMessage+`{{${data.value}}}`)} />
                </div>

              <Form>
                <TextArea
                  style={{
                    marginTop:'20px',
                    fontSize: '12px',
                    border: '1px solid black',
                    borderRadius: '10px',
                    // width: '470px',
                  }}
                  onChange={(e, data) => updateTextMessage(data.value)}
                  value={textMessage}
                  placeholder="Enter text message here"
                />
              </Form>
           </div> 
          ) : null}
          </>
        ) : null}
      </div>

      <Modal
        shouldCloseOnOverlayClick={false}
        isOpen={modalIsOpen}
        style={{
          overlay: {
            position: 'fixed',
            zIndex:100,
            top: 50,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0)',
          },
          content: {
            border: `.3px solid #0D99FF`,
            background: '#fff',
            overflow: 'auto',
            zIndex: 10,
            WebkitOverflowScrolling: 'touch',
            borderRadius: '10px',
            outline: 'none',
            padding: '0px',
          },
        }}
        contentLabel="Example Modal"
      >
        {/* 12-17-24 removed passing tableid, not needed and breaks with api_<tableid>, so don't use */}
        <EmailControl
          toAddress={'{{dynamic}}'}
          emailField={{
            fromAddress: emailMessage.fromAddress,
            replyTo: emailMessage.replyTo,
            fromName: emailMessage.fromName,
            CC: emailMessage.CC,
            BCC: emailMessage.BCC
          }}
          replyMsg={emailMessage}
          color="#0D99FF"
          sendMessage={configureEmail}
          close={() => setModalIsOpen(false)}
          role={3}
          tokens={allFields}
        />
      </Modal>
    </div>
  );
};

export default ActionSendMessage;
