/* eslint-disable */
import React, { useEffect, useState } from 'react';
import Icon from '@mdi/react';
import { mdiPlusCircle, mdiCloseCircle } from '@mdi/js';

const HistoryTag = ({color,textColor,update,text}) => {
    return (
        <div style={{
            position: 'relative',
            display:'inline-flex',
            flexDirection:'row',
            margin: '5px',
            borderRadius: '10px',
            color: textColor,
            backgroundColor: color,
            paddingTop: '3px',
            paddingBottom: '3px',
            paddingRight: '5px',
            paddingLeft: '5px',
            whiteSpace:'nowrap',
            border:update === 'r'
            ? `2px solid #F6685E`
            : update === 'n'
            ? '2px solid #93E088'
            : null,
          }}>
            {text}
        {update === 'n' ? (
                <div style={{ position: 'absolute', top: -12, right: -5, zIndex: 90 }}>
                <Icon path={mdiPlusCircle} size="15px" color="#93E088" />
                </div>
            ) : null}
            {update === 'r' ? (
                <div style={{ position: 'absolute', top: -12, right: -5, zIndex: 90 }}>
                <Icon path={mdiCloseCircle} size="15px" color="#F6685E" />
                </div>
            ) : null}
        </div>
    )
}

export default HistoryTag;