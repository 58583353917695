/* eslint-disable */
import React, {useEffect, useState,useContext } from 'react';
import { AccountContext } from '../../../../providers/AccountProvider';
import _ from 'lodash';
import { Checkbox} from 'semantic-ui-react';
import {
    mdiBell,
    mdiInformationOutline
  } from '@mdi/js';
  import Icon from '@mdi/react';
import GBSwitch from 'src/components/GBSwitch/GBSwitch';
import {getBlockUserSettings,updateBlockUserSettings} from '../../../../api/blocks'
import Global from '../../../../clients/global'



const Settings = ({color,blockid}) => {
    const { userInfo} = useContext(AccountContext);
    const [settings, setSettings]=useState({});
    const [textEnabled,setTextEnabled]=useState(false);
    const [recordsEnabled, setRecordsEnabled]=useState(false);
    const [messagesEnabled, setMessagesEnabled]=useState(false);
    const [emailReplyEnabled,setEmailReplyEnabled] =useState(false);
    const [usingTexting, setUsingTexting]=useState(false);

    useEffect(()=> {
        const currentZone = userInfo.zones.filter(el=>el.id===parseInt(Global.zoneid))[0];
        if (_.some(currentZone.attributes.PhoneNumbers, function(currentNumber) {
            return currentNumber.isActive === true;
        })) {
            setUsingTexting(true);
        } 
        getSettings();
    },[])


    const getSettings = async () => {
        const settings = await getBlockUserSettings(blockid);
        
        setSettings(settings);
        setTextEnabled(_.get(settings, 'notifications.textmessages.isEnabled', true));
        setRecordsEnabled(_.get(settings, 'notifications.recordNotifications.isEnabled', true))
        setMessagesEnabled(_.get(settings, 'notifications.messageNotifications.isEnabled', true))
        setEmailReplyEnabled(_.get(settings, 'notifications.emailReplyNotifications.isEnabled', true))
    
        // _.get(object, path, [defaultValue])
    }

    const setNotificationEnabled = async (name,value) => {
        if(name==='textmessages') {
            setTextEnabled(value)
            await setSettingValue('textmessages','isEnabled',value)
        } else if (name==='recordNotifications') {
            setRecordsEnabled(value);
            await setSettingValue('recordNotifications','isEnabled',value);
        } else if (name==='messageNotifications') {
            setMessagesEnabled(value);
            setSettingValue('messageNotifications','isEnabled',value)
        } else if(name==='emailReply') {
            setEmailReplyEnabled(value);
            setSettingValue('emailReplyNotifications','isEnabled',value)
        }
    }


    const setSettingValue= async (notificationName,setting,value) =>{
       
        const tempSettings = {...settings};
       
        if(tempSettings.notifications===undefined){
            tempSettings.notifications={[notificationName]:{[setting]:value}}
        } else if (tempSettings.notifications[notificationName]===undefined )
        {
            tempSettings.notifications[notificationName]={[setting]:value}
        }
        else
        {
            tempSettings.notifications[notificationName][setting]=value;
        }
        console.log(notificationName)
        console.log(tempSettings)
        await updateBlockUserSettings(blockid,tempSettings);
        setSettings(tempSettings);

    }

    const getSettingValue =(notificationName, setting) => {
        if(settings.notifications ===undefined || settings.notifications[notificationName] ===undefined || settings.notifications[notificationName][setting]===undefined || settings.notifications[notificationName][setting]===true){
            return true;
        } else {
            return false;
        }
    }

    return (
        <div style={{margin:'10px'}}>
            <div style={{marginBottom:'10px', display:'flex', alignItems:'center'}}>
             To learn more about notification settings,
             <div style={{width:'5px'}} />
             <a style={{color:'blue', textDecoration:'underline'}} href="https://www.graceblocks.com/support-article/notifications" target="_newwindow">
             <div style={{width:'5px'}} />
             click here 
             </a>
             .
            </div>
            {usingTexting ? (<>
            <div style={{display:'flex',flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
                <div><b>Text messages</b></div>
                <div><GBSwitch color={color} isChecked={textEnabled} Action={setNotificationEnabled} ActionValue='textmessages' ActionValue2={!textEnabled} /></div>
            </div>
            <div>
            Receive notification when a reply message is received from a number you have texted. (This includes replies to workflow texts you trigger.)
            </div>
            {textEnabled ? (
            <div style={{display:'flex', flexDirection:'row', alignItems:'center',marginTop:'5px'}}>
                <div style={{display:'flex',flexDirection:'row',alignItems:'center', height:'30px'}}>
                    <div style={{marginRight:'5px'}}><b>Send to:</b></div>
                    <div style={{marginRight:'5px',marginTop:'3px'}}>
                        <Checkbox 
                        checked={getSettingValue('textmessages','bell')} 
                        onChange={(e,data)=>setSettingValue('textmessages','bell',data.checked)}
                        /></div>
                    <div style={{marginBottom:'1px'}}><Icon path={mdiBell} color={color} size="20px" /></div>
                 </div>
                 <div style={{marginLeft:'15px',display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <div style={{marginRight:'5px'}}><b>App push notices:</b> </div>
                    <div style={{marginRight:'5px',marginTop:'3px'}}>
                        <Checkbox 
                        checked={getSettingValue('textmessages','push')} 
                        onChange={(e,data)=>setSettingValue('textmessages','push',data.checked)}
                        /></div>
                 </div>
            </div>
            ):null}
            </>):null}
            {/* Collaboration assignmentgs */}
            <div style={{marginTop:'20px',display:'flex',flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
                <div><b>Collaborator assignments</b></div>
                <div><GBSwitch color={color} isChecked={recordsEnabled} Action={setNotificationEnabled} ActionValue="recordNotifications" ActionValue2={!recordsEnabled} /></div>
            </div>
            <div>
            Receive notification when a record is updated where you are an assigned collaborator.
            </div>
            {recordsEnabled ? (
            <div style={{display:'flex', flexDirection:'row', alignItems:'center',marginTop:'5px'}}>
                <div style={{display:'flex',flexDirection:'row',alignItems:'center', height:'30px'}}>
                    <div style={{marginRight:'5px'}}><b>Send to:</b></div>
                    <div style={{marginRight:'5px',marginTop:'3px'}}>
                        <Checkbox 
                        checked={getSettingValue('recordNotifications','bell')} 
                        onChange={(e,data)=>setSettingValue('recordNotifications','bell',data.checked)}
                        /></div>
                    <div style={{marginBottom:'1px'}}><Icon path={mdiBell} color={color} size="20px" /></div>
                 </div>
                 <div style={{marginLeft:'15px',display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <div style={{marginRight:'5px'}}><b>Email:</b> </div>
                    <div style={{marginRight:'5px',marginTop:'3px'}}>
                        <Checkbox 
                        checked={getSettingValue('recordNotifications','email')} 
                        onChange={(e,data)=>setSettingValue('recordNotifications','email',data.checked)}
                        /></div>
                 </div>
                 <div style={{marginLeft:'15px',display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <div style={{marginRight:'5px'}}><b>App push notices:</b> </div>
                    <div style={{marginRight:'5px',marginTop:'3px'}}>
                        <Checkbox 
                        checked={getSettingValue('recordNotifications','push')} 
                        onChange={(e,data)=>setSettingValue('recordNotifications','push',data.checked)}
                        /></div>
                 </div>
            </div>
            ):null}
            {/* End Record Notifications */}
            {/* Collaborator Messages */}
            <div style={{marginTop:'20px',display:'flex',flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
                <div><b>Collaborator messages</b></div>
                <div><GBSwitch color={color} isChecked={messagesEnabled} Action={setNotificationEnabled} ActionValue="messageNotifications" ActionValue2={!messagesEnabled} /></div>
            </div>
            <div>
            Receive notification when someone mentions you in a collaboration message.
            </div>
            {messagesEnabled ? (
            <div style={{display:'flex', flexDirection:'row', alignItems:'center',marginTop:'5px'}}>
                <div style={{display:'flex',flexDirection:'row',alignItems:'center', height:'30px'}}>
                    <div style={{marginRight:'5px'}}><b>Send to:</b></div>
                    <div style={{marginRight:'5px',marginTop:'3px'}}>
                        <Checkbox 
                        checked={getSettingValue('messageNotifications','bell')} 
                        onChange={(e,data)=>setSettingValue('messageNotifications','bell',data.checked)}
                        /></div>
                    <div style={{marginBottom:'1px'}}><Icon path={mdiBell} color={color} size="20px" /></div>
                 </div>
                 <div style={{marginLeft:'15px',display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <div style={{marginRight:'5px'}}><b>Email:</b> </div>
                    <div style={{marginRight:'5px',marginTop:'3px'}}>
                        <Checkbox 
                        checked={getSettingValue('messageNotifications','email')} 
                        onChange={(e,data)=>setSettingValue('messageNotifications','email',data.checked)}
                        /></div>
                 
                 </div>
                 <div style={{marginLeft:'15px',display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <div style={{marginRight:'5px'}}><b>App push notices:</b> </div>
                    <div style={{marginRight:'5px',marginTop:'3px'}}>
                        <Checkbox 
                        checked={getSettingValue('messageNotifications','push')} 
                        onChange={(e,data)=>setSettingValue('messageNotifications','push',data.checked)}
                        /></div>
                
                 </div>
            </div>
            ):null}

              {/* Email Reply */}
              <div style={{marginTop:'20px',display:'flex',flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
                <div><b>Email replies</b></div>
                <div><GBSwitch color={color} isChecked={emailReplyEnabled} Action={setNotificationEnabled} ActionValue="emailReply" ActionValue2={!emailReplyEnabled} /></div>
            </div>
            <div>
            Receive a notification when a reply email is received from an email you have sent.(This includes replies to workflow emails you trigger.)
            </div>
            {emailReplyEnabled ? (
            <div style={{display:'flex', flexDirection:'row', alignItems:'center',marginTop:'5px'}}>
                <div style={{display:'flex',flexDirection:'row',alignItems:'center', height:'30px'}}>
                    <div style={{marginRight:'5px'}}><b>Send to:</b></div>
                    <div style={{marginRight:'5px',marginTop:'3px'}}>
                        <Checkbox 
                        checked={getSettingValue('emailReplyNotfications','bell')} 
                        onChange={(e,data)=>setSettingValue('emailReplyNotfications','bell',data.checked)}
                        /></div>
                    <div style={{marginBottom:'1px'}}><Icon path={mdiBell} color={color} size="20px" /></div>
                 </div>
                 <div style={{marginLeft:'15px',display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <div style={{marginRight:'5px'}}><b>Email:</b> </div>
                    <div style={{marginRight:'5px',marginTop:'3px'}}>
                        <Checkbox 
                        checked={getSettingValue('emailReplyNotfications','email')} 
                        onChange={(e,data)=>setSettingValue('emailReplyNotfications','email',data.checked)}
                        /></div>
                 
                 </div>
                 <div style={{marginLeft:'15px',display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <div style={{marginRight:'5px'}}><b>App push notices:</b> </div>
                    <div style={{marginRight:'5px',marginTop:'3px'}}>
                        <Checkbox 
                        checked={getSettingValue('emailReplyNotfications','push')} 
                        onChange={(e,data)=>setSettingValue('emailReplyNotfications','push',data.checked)}
                        /></div>
                
                 </div>
            </div>
            ):null}
        
        </div>
    )
}

export default Settings