/* eslint-disable no-else-return */
/* eslint-disable consistent-return */
/*
SUMMARY: mph - 4-11-20 
This set of methods is responsible for inserting/updating all data changes made directly on the grid/table.
Whenever you make a change on the grid, the "afterchanges" method is invoked which has the ID of the row, the before value
and the after value. The grid calls the addorupdateValues method for any change, regardless of data type, select field,et

The data that is sent is then used to determine whether we are updating a single record, a select /options list or 
a relational insert. If the ID passed in is null, this means a user added data to a new row that did not yet exist
The method will first insert an emptry row, generate the ID, and then use this ID to update the appropriate data.

For select lists, the entire set of values is sent and we do a simple delete of all values, then re-insert. This
is easier to implement than trying to delete /insert specific values. Also, we have deteremined that the date of
those entries is not something we care or use...so a complete replacement is acceptable.

If a user is adding a new relational record from the pop-up form, or when we expose the form to add a new record,
this logic is separate and processed in the newRecord.js method.

PARAMS
[id:integer] This is the data id of the row being updated. If it's a new role, null is sent.
[table: string]: This is the internal tablename (tbl_133) that is used to construct which table is getting update/inserted
[field: string]: this is the internal fieldname (field_8) that is getting updated.
[newvalue: object]: this is the new value the user made to the cell. It could be a string, number, date or complext type like array of list objects or array or relational field objects.
[oldvalue: object], this is the orginal value/previous value of the cell prior to the update.
[CLICK: function], This is passed in Function to call IF a new ID is generated. When the method needs to create the ID 
dynamically. It generate the ID and at end of method calls CLICK, which is executed in block.jsx object to inserr the new ID for that row. Allows Grid to be updated dyamnically.
[row: integer]: This is the actual row number of grid that was updated. This is needed in cases where new ID is generated.
[lookup: string]: This is either "single" or "multiple". This is defined for the column type and is used to structure appropriate graphql
[jointype: string]: Is used to determine if field is regular or relational. If relational, it calls relational update method
[lookuptable: string]: For relational insert/updates, is used to define the relational table that is used to structure graphql 
[jointable: string]: for relational insert/updates, this is special join table where tableid's are stored,
[schema: string]: This is schema that user is part of (zone is schema_zoneid)./ This comes from JWT token

*/
/* eslint-disable */
import cloudrun from '../clients/httpcloudrun';

// 6-14-2022 adding this method to support new functionality of needing to click the "+" icon
//to insert new row to grid, which will return full row with all default values. This is done
//for speed reasons when updating new values. This will ensure row is loaded prior to needing to start to update values.
export const insertNewRow = async (tableid,tableinfo,blockid,role) => {
  const payload={tableid,tableinfo,blockid,role}
  const newRow = await cloudrun.post('/insertNewRow', {payload})
  return newRow;
}

export const addrelationalvalue = async (
  id,
  table,
  field,
  newvalue,
  row,
  lookuptable,
  jointable,
  header,
  returnfield,
  tableinfo,
  RefreshRow
) => {

  // added 8-11-2021 to deal with "Client was passed a null or undefined query"
 if(newvalue===''){
   return "success";
 }
 //see if any lookup fields 
 const hasLookupFields = tableinfo.columns.findIndex(col=>col.uitype===18 && (col.hide===undefined || col.hide===false) && col.source && col.source.lookuptable===lookuptable ) !==-1;

 // see if relational field used in any formula fielss
 const inFormulaFields = tableinfo.columns.findIndex(col=>col.uitype===16 && (col.hide===undefined || col.hide===false) && col.usedFields.some(f=>f.field===field && f.lookuptableid===parseInt(table.split('_')[1])) ) !==-1;

  //7-12-2022 Looking up if field is single/multiple. This is used in history node to determine
  //how to display the data change difference.
  let lookup='single';
  const idx = tableinfo.columns.findIndex(fld=>fld.data===field);
  if(idx !==-1) {
      lookup = tableinfo.columns[idx].lookup;
  }

// 8-3-2022 when user is on single select and chooses to remove the item, but doesn't choose a
//new value, we get a single entry and the type=-1. In this use-case, we essentially want to
//convert the value =null and set the type=2, which is the equivealent of hittind delete key on item
if(newvalue !==null && lookup==='single' && newvalue.length===1 && newvalue[0].type===-1) {
  newvalue = null;
}

  const payload={id,table,field,header:header,returnfield:returnfield,jointype:'relational',lookuptable,jointable,items:newvalue,lookup,tableinfo};
  const result = await cloudrun.post('/addRemoveRelationalItems', {payload})
  
  if((hasLookupFields || inFormulaFields)  && RefreshRow  && result==='success')
  {
    console.log('in crud refresh row')
    await RefreshRow(id,'id',row,lookuptable)
  }
  return result;

};


export const addsinglefieldvalue = async (id, table, field, value,oldvalue, CLICK, row,schema,userid,uitype,tableinfo,colinfo,RefreshRow) => {
  
  let newval=null;
  if(value !==null) newval=value
  const finalschema=schema; //schema could be different than schema in token
  let result=''

  //7-15-2022 Adding check, if value is not changed, do nothing.
  //On grid, this happens if you click into a cell and then click out without changing anything
  //it still trigger change.
  if(value==oldvalue || value==='') {
    return "success";
  }

  //4-28-23 check if any formula fields exist. if yes, call refresh row.
  const inFormulaFields = tableinfo.columns.findIndex(col=>col.uitype===16 && (col.hide===undefined || col.hide===false) && col.usedFields.some(f=>f.field===field && f.lookuptableid===parseInt(table.split('_')[1])) ) !==-1;
  const payload={table,id,tableinfo,field,finalschema,userid,value:newval,uitype,header:colinfo.header}

  try {
    result = await cloudrun.post('/insertOrUpdateSingleRowValue', {payload})
    if(inFormulaFields){
      await RefreshRow(id,'id',row,'formula')
    }
    return "success"
    
    } catch(error) {
      return error.message
    }

  };

// Support for deleting custom table data as well as system table data.
// system type tables (blockusers, zoneusers, etc) will have a flag in tableinfo metadata called isSystemTable = true.
// if system table, we pass use the tablename from metadata. this will match the actual system table.

export const deleteRows = async (id, tableid,schema,isSystemTable,tablename) => {

  if(id===undefined || id===null)
  {
    return false;
  }

    const payload={id,tableid,schema,isSystemTable,tablename,dbfunction:'deleteRows'}
    await cloudrun.post('/executeQueryAPI', {payload})
    
    // 6-21-24 we were going to support workflow delete record as trigger, but as of today, have decided against it.
    //  await cloudrun.post('/deleteRows',{payload})
};


export const addmultiplefieldvalue = async (id, table, field, value, oldvalue, CLICK, row, userid,tableinfo,uitype,colinfo,RefreshRow) => {

  // 11-12-2021 Use-case dealing with collaborator selection to start a new row.
  // if they click to select an item, then decide to cancel, there is still a row
  // in array with userid=null. If they select a value, that empty entity is still
  // in the array. So, we filter out all null userid's . If that leaves array empty,
  // we jsut return, and if there are values, we have removed the null userid, so it will process
  // correctly. A bit of a hack, but the way the renderer works, it detects changes simply by
  // clicking into the row and the handsontable minsparerow unfortunately has a skeleton of the
  // object instead of just being null. That is where we should look for better solution, so
  // minsparerow has all null values by default.
  
  if(uitype===8 && Array.isArray(value)){
    value=value.filter(el=>el.userid !==null);
    if(value.length==0) {
      value=null
    }
  }

  //7-15-2022 Rigth now if you click into a single/multi/collab field and dont' change the value, it
  //still triggers a db action. Here, we say if value hasn't changed, don't do anything
  if(JSON.stringify(value)===JSON.stringify(oldvalue)){
    return "success";
  }
 
  // added 8-11-2021 this gets passed when clicking to open up dropdown, but then you click away.
  // the close code from the renderer passes the empty string, triggeing changed value logic.
  if(value=='') {
    value=null
  } 


  const payload={id,table,field,value,userid,tableinfo,uitype,header:colinfo.header}
  
  const result = await cloudrun.post('/addListValue', {payload})

   //4-28-23 check if any formula fields exist. if yes, call refresh row.
   const inFormulaFields = tableinfo.columns.findIndex(col=>col.uitype===16 && (col.hide===undefined || col.hide===false) && col.usedFields.some(f=>f.field===field && f.lookuptableid===parseInt(table.split('_')[1])) ) !==-1;

   if(inFormulaFields){
    await RefreshRow(id,'id',row,'formula')
  }
  return "success"
};

// this method is specific to adding/or updating a block user to the BlockUsers table.
export const addorUpdateBlockUser = async (id,newvalue,CLICK,row,userid,blockid,RefreshRow,isAdminBlock) => {
  const newDate = new Date();

  // 6-2-2020. We need to support the "Users in Block" tab, so that a user can be added separate
  // from the "Users" block. In this use-case, a user is added and THEN a block is selected. 
  // We need to change query if this is "Users in block" tab.
  if(id==='' && newvalue===''){
    return false 
  }

  try {
  if(id ==='')
  {
    const payload={blockid,role:'Block user (General)',pos:1,userid:newvalue.userid,dbfunction:'addBlockUser'}
    const dbresult = await cloudrun.post('/executeQueryAPI', {payload})
    const newid =dbresult[0].id
    await CLICK(row, newid,true);
    return "success"

  } else {

    const payload={id,userid:newvalue.userid,dbfunction:'updateBlockUser'}
    const dbresult = await cloudrun.post('/executeQueryAPI', {payload})
    await RefreshRow(id,'id',row)
    return "success"
  }
} catch(error) {
  return error.message
}
}


export const PasteNewUsers = async (emailAddresses,zoneid,refreshTable,userid,blockid) => {

  const loadUsers = async () => {
    return Promise.all(
      emailAddresses.map(async (email) => {
          await UpdateUsersTable('','email',email[0],null,null,userid,zoneid,null,false,2,blockid)
      }),
    );
  };
  
  loadUsers();
  refreshTable();
}


// Method specifically for updating the central Users table. Special logic is needed
// to insert a user into users/zoneusers table by default if not previously existing. and then updating User info directly.

export const UpdateUsersTable = async (currentUserid,field,newvalue,CLICK,row,userid,zoneid,RefreshRow,refreshGrid=true,uitype,blockid) =>{
  

  // 10-7-2020 if they change email or zone role, call addUserViaGrid
  let result=''
  if(uitype===10 || uitype===2){
    const payload={currentUserid,field,newvalue,userid,blockid}
    result = await cloudrun.post('/addUserViaGrid', {payload})
    if(result.error !==undefined) {
      return result.error
    }
  } else if (uitype===30) {
    const payload={currentUserid,blocks:newvalue,dbfunction:'updateUserBlocks'}
    result = await cloudrun.post('/executeQueryAPI', {payload})
  }
  
  if(refreshGrid) //6-2-2020 added this param so that we could re-use this method for copy/paste of email addreseses to load table. In that situation, we don't want to refresh gid.
  {
    if(result.newid !== undefined) {
    await CLICK(row, result.newid);
    await RefreshRow(result.newid,'userid',row)
    }
  }

  return "success"
}


export const addAttachments = async (id, table, field, value, oldvalue, CLICK, row, userid,tableinfo,colinfo) => {

  const payload={id,table,field,value,userid,tableinfo,header:colinfo.header}
  // added 8-12-2021 to deal with "Client was passed a null or undefined query"
  if(value==='' || JSON.stringify(value)===JSON.stringify(oldvalue)){
    return "success";
  }

  const result = await cloudrun.post('/addAttachments', {payload})
    return "success"
}


export const addorupdateValues = async (
  id,
  table,
  field,
  newvalue,
  oldvalue,
  CLICK,
  row,
  lookup,
  jointype,
  lookuptable,
  jointable,
  schema,
  userid,
  uitype,
  blockid,
  RefreshRow,
  isAdminBlock,
  colinfo,
  tableinfo
) => {

  // This is a check when a user is clicking into an empty cell, then out...it still triggers afterchange.
  // and in that case, we do nothing. 
  if((oldvalue===undefined || oldvalue==='') && newvalue==='')
  {
    return
  }
  if (jointype === 'relational') {
   return addrelationalvalue(
      id,
      table,
      field,
      newvalue,
      row,
      lookuptable,
      jointable,
      colinfo.header,
      colinfo.source.returnfield,
      tableinfo,
      RefreshRow
   );
  } else if (lookup === 'text') {
   return await addsinglefieldvalue(id, table, field, newvalue,oldvalue, CLICK, row,schema,userid,uitype,tableinfo,colinfo,RefreshRow);
  } else if (uitype===6 || uitype=== 7 || uitype===8) {
   return  addmultiplefieldvalue(id, table, field, newvalue,oldvalue, CLICK, row,userid,tableinfo,uitype,colinfo,RefreshRow);
  } else if(uitype===26) { // uitype=26 is specific field type to process BlockUser user field.
    return addorUpdateBlockUser(id,newvalue,CLICK,row,userid,blockid,RefreshRow,isAdminBlock)
  } else if(uitype===4) {
    return addAttachments(id, table, field, newvalue,oldvalue, CLICK, row, userid,tableinfo,colinfo)
  } 
};
