/* eslint-disable */
import React from 'react';
import { mdiClose } from '@mdi/js';
import { Input } from 'semantic-ui-react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import TextButton from 'src/components/TextButton/TextButton';
import GBButton from 'src/components/GBButton/GBButton';
import SectionItem from './SectionItem';
import SectionFields from './SectionFields';

class FieldLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sections: structuredClone(props.tableinfo.sections) ?? [],
      newSectionName: '',
      isLoading: false,
      editMode: false,
      selectedSection: null,
    };
    // Initialize draggedItemRef in the constructor
    this.draggedItemRef = null;
    this.dragStartMouseX = null;
    this.dragStartMouseY = null;
  }

  updateSectionName = (oldName, newName) => {
    const { sections } = this.state;
    //We can't let them change the name of section to one that already exists.
    const idx = sections.findIndex(
      (sec) =>
        sec.name.toLowerCase() !== oldName.toLowerCase() &&
        sec.name.toLowerCase() === newName.toLowerCase(),
    );
    if (idx !== -1) {
      //show message the section names must be unique.
      return false;
    } else {
      const idx2 = sections.findIndex((sec) => sec.name === oldName);
      if (idx2 !== -1) {
        sections[idx2].name = newName;
        this.setState({ sections });
      }

      return true;
    }
  };

  handleDeleteSection = (name) => {
    const { sections } = this.state;
    const updatedSections = sections.filter((el) => el.name !== name);
    this.setState({ sections: updatedSections });
  };

  handleAddSection = () => {
    const { sections, newSectionName } = this.state;
    const { tableinfo } = this.props;

    const idx = sections.findIndex((sec) => sec.name === newSectionName);
    if (idx !== -1) {
      return false;
    } else {
      const section = {
        name: newSectionName,
        count: 0,
        fields:[],
        display: true,
        collapse: false,
        insertAt: tableinfo.columns.length + 1,
        sectionNumber: sections.length + 1,
      };
      sections.push(section);
      this.setState({ sections, newSectionName: '' });
    }
  };

  handleInputChange = (event) => {
    this.setState({ newSectionName: event.target.value });
  };

  editSectionFields = (section) => {
    this.setState({ editMode: true, selectedSection: section });
  };

  closeEditFields = () => {
    this.setState({ editMode: false });
  };

  getFields = () => {
    const { tableinfo } = this.props;
    let filterFields = tableinfo.columns
      .filter(
        (itm) =>
          itm.data !== 'id' &&
          itm.removeColumn === undefined && // added 12-30-2021 to remove "locked"/hidden columns from user who does not have access
          ((itm.uitype === 1 && itm.lookup === 'single') || itm.uitype !== 1) &&
          itm.uitype !== 12 &&
          itm.uitype !== 4 &&
          (!itm.source || (itm.source && itm.source.lookupuitype !== 4)),
      )
      .sort((a, b) => (a.detailviewsort > b.detailviewsort ? 1 : -1));
    return filterFields;
  };

  updateFields =(section,newFields) =>{

    const {sections}   = this.state
    const idx = sections.findIndex(sec=>sec.name===section.name);
    
    if(idx !==-1) {
      sections[idx].fields = newFields;
      sections.filter(sec=>sec.name!==section.name).map(sec=>{
        const updatedSecFields = sec.fields.filter(
          (field) => !newFields.some((newField) => newField === field)
        );
         sec.fields = updatedSecFields; // Update the original sec.fields array
      })

    }

    this.setState({sections,editMode:false});
  }


  getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? 'white' : 'white',
    padding: 10,
    width: 350,
  });

  getItemStyle = (isDragging, draggableStyle) => {
    if (!isDragging) {
      // Default styles when not dragging
      return {
        userSelect: 'none',
        width: '700px',
        marginTop: '10px',
        // marginLeft: '20px',
        background: 'white',
        ...draggableStyle,
      };
    }

    if (this.draggedItemRef) {
      const rect = this.draggedItemRef.getBoundingClientRect();
      const offsetX = this.dragStartMouseX - rect.left;
      const offsetY = this.dragStartMouseY - rect.top;
      return {
        position: 'fixed',
        top: this.dragStartMouseY - offsetY,
        left: this.dragStartMouseX - offsetX,
        width: rect.width,
        height: rect.height,
        zIndex: 1000,
        userSelect: 'none',
        background: `${this.props.color}80`,
        ...draggableStyle,
      };
    }

    // Fallback styles (should not normally be reached during dragging)
    return {
      userSelect: 'none',
      width: '500px',
      marginLeft: '20px',
      background: 'white',
      ...draggableStyle,
    };
  };

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  onDragStart = (start) => {
    const draggableId = start.draggableId;
    const section = this.state.sections.find((s) => s.name === draggableId);
    if (section) {
      const index = this.state.sections.indexOf(section);
      const draggable = document.querySelector(`[data-rbd-draggable-id='${draggableId}']`);
      if (draggable) {
        this.draggedItemRef = draggable;
        const rect = this.draggedItemRef.getBoundingClientRect();
        this.dragStartMouseX = start.source.x;
        this.dragStartMouseY = start.source.y;
      }
    }
  };

  onDragEnd = (result) => {
    this.draggedItemRef = null; // Reset the ref when dragging ends
    this.dragStartMouseX = null;
    this.dragStartMouseY = null;

    if (!result.destination) {
      return;
    }

    const sections = this.reorder(
      this.state.sections,
      result.source.index,
      result.destination.index,
    );

    this.setState({
      sections,
    });
  };

  render() {
    const { color, setShowFieldLayout,updateTable,tableid } = this.props;
    const { sections, newSectionName, isLoading, selectedSection, editMode } = this.state;

    return (
      <div>
        <div
          style={{
            backgroundColor: color,
            color: 'white',
            height: '50px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '10px',
          }}
        >
          <div style={{ fontSize: '20px' }}>Edit details page layout</div>
          {/* <div>
            <TextButton
              fontSize="20px"
              Action={() => setShowFieldLayout(false)}
              textColor="white"
              text="Hide panel when done"
              icon={mdiClose}
            />
          </div> */}
        </div>
        <div style={{ width: '100%' }}>
          <div
            style={{
              alignSelf: 'center',
              margin: '20px',
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div>Create new section</div>
            <div style={{ width: '300px', marginLeft: '10px', marginRight: '10px' }}>
              <Input fluid value={newSectionName} onChange={this.handleInputChange} />
            </div>
            <div>
              <GBButton text="Save section" color={color} width="150px" Action={this.handleAddSection} />{' '}
            </div>
          </div>
        </div>
        <hr />
        {!editMode ? (
          <div style={{height:'calc(100vh - 290px)',overflow:'auto' }}>
            <div
              style={{
                marginLeft: '50px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginBottom: '10px',
              }}
            >
              <div style={{ width: '100px', textAlign: 'center', color: '#ACACAC' }}>ORDER</div>
              <div style={{ width: '300px' }}>Section name</div>
              <div style={{ width: '200px', textAlign: 'center' }}>Actions</div>
            </div>

            <DragDropContext onDragEnd={this.onDragEnd} onDragStart={this.onDragStart}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {sections.length > 0 &&
                      !isLoading &&
                      sections.map((item, index) => (
                        <Draggable key={item.name} draggableId={item.name} index={index}>
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={this.getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style,
                              )}
                            >
                              <SectionItem
                                section={item}
                                index={index}
                                color={color}
                                updateSectionName={this.updateSectionName}
                                deleteSection={this.handleDeleteSection}
                                editSectionFields={this.editSectionFields}
                              />
                            </div>
                          )}
                        </Draggable>
                      ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>

            <div style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'flex-end',margin:'20px',marginRight:'100px'}}>
              <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
              <TextButton textColor="black" hoverColor="#00000080" text="Cancel" Action={() => setShowFieldLayout(false)} />
              <div style={{width:'20px'}}></div>
              <GBButton Action={()=>updateTable(tableid,sections)} width='155px' text='Save layout' color={color} />
              </div>
            </div>
          </div>
        ) : (
          <div>
            <SectionFields
              color={color}
              section={selectedSection}
              sections={sections}
              fields={this.getFields()}
              close={this.closeEditFields}
              updateFields ={this.updateFields}
            />
          </div>
        )}

      </div>
    );
  }
}

export default FieldLayout;
