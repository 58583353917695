/* eslint-disable no-console */
/*
SUMMARY mph 4-11-20
This is a bit of a mixed file which was created mainly as a Data API for the lookup filter components.
2 methods, GetListData and RunQueryAPI are used generally to simply execute an existing graph ql query
As such, they should probably be moved to a more common API file such as common.js. ***FIX***

The other methods are used to retrieve relational or list specific data which is used /called by
the filters on the grid page. When you click to add a relational filter for example, it uses these
methods to retrieve the appropriate data from that relationshp. Same with options filters
The components that call this api: 
DAtaList.jsx, TableLink.jsx, Block.jsx >> RunQueryAPI
LookupFilter: >>GetLookupOptionTableData
OptionsFilter: >>GetSpecialListData
RelationFilter: >>GetRelationTableData

*/

/* eslint-disable */
import cloudrun from '../clients/httpcloudrun';

export const GetListData = async (tableid,field) => {

    const payload={tableid,field,dbfunction:'getOptionsApi'}
    const dataresponse = await cloudrun.post('/executeQueryAPI', {payload})
    const response = dataresponse[0].root;
    return response;

};


// This method is used in OptionSelect control to retreive unique value from a textfield, 
// which is then used to populate the option list. 
// API call first checks count, if over 100 unqiue items, returns error, otherwise returns unique values.
export const GetUniqueFieldValues = async (schema, table, field) => {

    const payload={table,field,dbfunction:'getUniqueFieldValues'}
    const dataresponse = await cloudrun.post('/getUniqueFieldValues', {payload})
    if(dataresponse.error !==undefined) {
      return dataresponse
    } else{
      const response = dataresponse[0].root;
      return response;
    }
}

export const clearCacheByRequest = async (tableid) =>{
  const payload={tableid};
  await cloudrun.post('/clearCacheByRequest', {payload})
}

export const GetALLFieldValues = async (schema, table, field) => {

  const payload={table,field,dbfunction:'getAllFieldValues'}
  const dataresponse = await cloudrun.post('/executeQueryAPI', {payload})
  const response = dataresponse[0].root;
  return response;
}

// 9-14-2020 used to retrieve a specific field from a specific table. The first use case is that
// we need to retrieve the phonenumber from a table for sending messages(when no previous messages have been sent.)
// the phone number field is dyhamic per table.
export const getFieldFromTable = async (tableid,field,id) => {
  const payload = {tableid,field,id,dbfunction:'getFieldValue'}
  const dataresponse = await cloudrun.post('/executeQueryAPI', {payload})
  if(dataresponse.length>0){
  return dataresponse[0][field]
  } else {
    return ''
  }
}

// This method converts the select options back to text values to store in the table. 
export const MapOptionValuesToText = async (table,field,maxlength) => {
  
  const payload={table,field,maxlength}
  const result = await cloudrun.post('/mapOptionValuesToText', {payload}) 
  return "success"
}

export const MapTextValuesToOptionValues = async (schema, table, field) => {

  const payload={table,field}
  const result = await cloudrun.post('/mapTextValuesToOptionValues', {payload}) 
  return "success"

}

export const getTableRelationalIdentifiers = async (tableid) => {

  const payload={tableid,dbfunction:'getTableRelationalIdentifiers'}
  const tabledata = await cloudrun.post('/executeQueryAPI', { payload });
  if(tabledata.length>0) {
    return tabledata;
  } else {
    return [];
  }

}

export const GetSingleDataRow = async (id,tableinfo,tableid,blockid,callingComponent='RefreshRow') => {

  const filters = [{field: 'id',type: 'table', uitype:100, displayfield: 'id', value: id,maxID:0}];

  let payload={
    tableinfo,
    tableid,
    blockid,
    limit:1,
    offset:0,
    filters:filters,
    sortfield:'id',
    sortorder:'asc',
    callingComponent:callingComponent  //2-6-24 added this so that when you drill into related record, it gets ALL lookup data.12/3/24 added it as param, so can pass "detail" to get loimited details fields
  }
    const tabledata = await cloudrun.post('/ExecuteTableQuery', { payload });

    let shapedData = tabledata.rows;
    let singleRow=null;
    if(shapedData.length>0) {
      singleRow=shapedData[0];
    }
   return singleRow
}

export const GetRelationalIdentifier = async (schema,tableid, id) => {

    const payload={tableid,id,dbfunction:'getRelationalIdentifier'}
    const dbresponse = await cloudrun.post('/executeQueryAPI', {payload})
    // console.log(dbresponse[0])
    return dbresponse[0].fullname
}

// 12-9-2021 getDefaultID and getDefaultOption were added to support showing defined default field
// values in the newRecord form. These are stored as text strings, and must be converted to the actual
// defined object to pass in as selected values. 
export const getDefaultID = async (tableid,defaultText) =>{

  const payload={tableid,defaultText,dbfunction:'getDefaultID'}
  const dbresponse = await cloudrun.post('/executeQueryAPI', {payload})
  return dbresponse[0];
}

export const getDefaultOption = async (tableid,field,defaultText) =>{

  const payload={tableid,field,defaultText,dbfunction:'getDefaultOption'}
  const dbresponse = await cloudrun.post('/executeQueryAPI', {payload})
  // console.log(dbresponse[0])
  return dbresponse[0];
}



let itemsarray = [];
const Scan = (obj, element) => {
  let k;
  if (obj instanceof Object) {
    // eslint-disable-next-line no-restricted-syntax
    for (k in obj) {
      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty(k)) {
        if (k === element) {
          itemsarray.push(obj[k]);
          // console.log(k);
          // console.log(obj[k])
        }
        Scan(obj[k], element);
      }
    }
  }

  return itemsarray;
};

export const GetLookupOptionTableData = async (tableinfo,fieldnode,tableid) => {

  const payload={tableinfo,fieldnode,tableid,dbfunction:'getListLookupValues'}
  const dbresponse = await cloudrun.post('/executeQueryAPI', {payload})
 
  const filterResults = dbresponse[0].json_agg

  const listdata = [];
  filterResults.forEach((el) => {
    const rowindex = listdata.findIndex((itm) => itm.value === el.optionid);
    if (rowindex === -1) {
      listdata.push({ key: el.optionid, value: el.optionid, text: el.option });
    }
  });
  listdata.sort((a, b) => (a.text > b.text ? 1 : -1));

  // reset Items arrya to emptry to clear out values.
  itemsarray = [];
  return listdata;
};

export const FieldHasData = async (schema, tableid, field,uitype) => {
  
  const payload={schema,field,tableid,uitype,dbfunction:'fieldHasData'}
  const dbresponse = await cloudrun.post('/executeQueryAPI', {payload})
  const result =parseInt(dbresponse[0].count);

  return result>0

}

export const getRecord = async (token) =>{
  const payload={token}
  const dbresponse = await cloudrun.post('/getRecord', {payload})
  return dbresponse
}