/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Dropdown, Input } from 'semantic-ui-react';
import Icon from '@mdi/react';
import { mdiChevronDown, mdiDelete, mdiCog, mdiPencil, mdiDragVertical } from '@mdi/js';
import GBButton from 'src/components/GBButton/GBButton';
import TextButton from 'src/components/TextButton/TextButton';

const SectionItem = ({ section, index, color, updateSectionName, deleteSection,editSectionFields }) => {
  const [name, setName] = useState(section.name);
  const [editMode, setEditMode] = useState(false);
  const [error, setError] = useState(null);


  const updateName = (newValue) => {
    setName(newValue);
  };

  const cancelChange = () => {
    setError(null);
    setEditMode(false);
    setName(section.name);
  };

  const handleNameChange = () => {
    const result = updateSectionName(section.name, name);
    if (result) {
      setEditMode(false);
      setError(null);
    } else {
      setError('Section names must be unique.');
    }
  };


  const sectionAction = (action) => {
    if (action === 'rename') {
      setEditMode(true);
    } else if (action == 'edit') {
      editSectionFields(section)
    } else if (action === 'delete') {
      deleteSection(section.name);
    }
  };

  const options = [
    {
      key: 'rename',
      text: (
        <div>
          <strong>Actions</strong>
        </div>
      ),
      disabled: true,
    },
    {
      key: '1',
      value: 'rename',
      text: 'Rename',
      icon: (
        <Icon style={{ marginRight: '5px', marginBottom: '3px' }} path={mdiPencil} size="20px" />
      ),
    },
    {
      key: '2',
      value: 'edit',
      text: 'Select fields and order',
      icon: <Icon style={{ marginRight: '5px', marginBottom: '3px' }} path={mdiCog} size="20px" />,
    },
    {
      key: '3',
      value: 'delete',
      text: 'Delete',
      icon: (
        <Icon style={{ marginRight: '5px', marginBottom: '3px' }} path={mdiDelete} size="20px" />
      ),
    },
  ];

  return (
    <div>
        <div
          style={{
            marginLeft: '50px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <div style={{ width: '100px', textAlign: 'center', color: '#ACACAC' }}>{index}</div>
          <div style={{ width: '300px' }}>
            {!editMode ? (
              section.name
            ) : (
              <div>
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <Input
                    style={{ width: '250px', marginRight: '5px' }}
                    fluid
                    value={name}
                    onChange={(e, data) => updateName(data.value)}
                  />
                  <TextButton
                    text="Cancel"
                    textColor="black"
                    hoverColor="red"
                    Action={cancelChange}
                  />
                  <GBButton
                    text="Save"
                    color={color}
                    textColor="white"
                    width="75px"
                    Action={handleNameChange}
                  />
                </div>
                {error ? <div style={{ color: 'red' }}>{error}</div> : null}
              </div>
            )}
          </div>
          <div style={{ width: '200px', textAlign: 'center' }}>
            <Dropdown
              upward={false}
              text=""
              icon={null}
              trigger={
                <GBButton
                  text="Select an action"
                  icon={mdiChevronDown}
                  iconSize="20px"
                  iconColor="white"
                  iconHoverColor="white"
                  iconPosition="right"
                  borderRadius="10px"
                  Action={null}
                  textColor="white"
                  width="150px"
                  height={'30px'}
                  color={color}
                  textHoverColor="white"
                  hoverBackgroundColor={`${color}80`}
                />
              }
              direction="left"
              selectOnBlur={false}
            >
              <Dropdown.Menu style={{ maxHeight: '300px', overflowY: 'auto' }}>
                {options.map((el) => (
                  <Dropdown.Item
                    key={el.key}
                    text={el.text}
                    icon={el.icon}
                    onClick={() => sectionAction(el.value)}
                  />
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div>
            <Icon path={mdiDragVertical} size="20px" />
          </div>
        </div>
   
    </div>
  );
};

export default SectionItem;
